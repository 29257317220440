import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './components/Home';
import Calendar from './components/Calendar';
import Results from './components/Results';
import Header from './components/Header';
import Footer from './components/Footer';
import { getUserData } from './utils/helper';
import UserContext from './utils/userContext';
const EXPIRED_CALENDAR = '2025-12-24T23:59:59';

const App = () => {
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState('');
    const [isExpired, setIsExpired] = useState(false);
    const location = useLocation();
    const search = location.search;
    console.log(userData);

    useEffect(() => {
        getUserId();
        isExpiredDate();
    }, []);

    useEffect(() => {
        if (userId !== '') {
            getUserData(userId)
                .then(data => {
                    if(data && data.length > 0) {
                        setUserData(data)
                    }
                })
        }
    }, [userId]);

    const isExpiredDate = () => {
        const now = Date.now();
        if (Date.parse(EXPIRED_CALENDAR) < now) {
            setIsExpired(true);
        }
    }

    const getUserId = () => {
        if (search && search.split('=')[0].indexOf('username') !== -1 && search.split('=').length > 1) {
            setUserId(search.split('=')[1]);
        }
    };

    return (
        <UserContext.Provider value={{ userId }}>
            <div className="decor fixed top-0 bottom-0 left-0 right-0 bg-main-blue bg-no-repeat bg-center bg-cover z-[0]">
                <span className="-xl:hidden top-l bg-[top_right]" />
                <span className="-xl:hidden top-r bg-[top_left]" />
                <span className="-xl:hidden bottom-l bg-[bottom_right]" />
                <span className="-xl:hidden bottom-r bg-[bottom_left]" />
            </div>
            <Header isExpired={isExpired}/>
            <Routes>
                {isExpired ? (
                    <Route path="/" element={<Results isExpired={isExpired} />} />
                ) : (
                    <>
                        <Route exact path="/" element={<Home/>} />
                        <Route path="/calendar" element={<Calendar userData={userData} setUserData={setUserData} />} />
                        <Route path="/results" element={<Results/>} />
                    </>
                )}
            </Routes>
            <Footer/>
        </UserContext.Provider>
    );
}

export default App;
